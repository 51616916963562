<template>
  <div>
    <div class="video-container">
      <video ref="video" id="video" controls autoplay width="100%" title="测试视频标题" controlslist="nodownload" >
        <source type="video/mp4" :src="bookVideoUrl">
      </video>
      <div class="audio-title">
        <p>古诗内容</p>
      </div>
      <div class="img-container">
      <img :src="book.bookImg">
    </div>
    </div>
  </div>
</template>

<script>
import {gradeList} from '/src/data/data2.js'
export default {
  name: "BookVideo",
  data() {
    return {
      videoLink: "",
      book:{},
      bookVideoUrl:"https://www.runoob.com/try/demo_source/movie.mp4",
    }
  },
  mounted() {
    let gradeIndex = this.$route.query.gradeIndex;
    console.log(gradeIndex)
    let bookIndex = this.$route.query.bookIndex;
    console.log(bookIndex)

    this.book = gradeList[gradeIndex].gradeBooks[bookIndex];
    this.bookVideoUrl = this.book.bookVideo;
    console.log('诗歌信息');
    console.log(this.book);
    console.log('视频链接信息');
    console.log(this.bookVideoUrl);
    this.$refs.video.play();
  }
}

</script>

<style scoped>
.video-container {
}

.img-container {
  margin-inline: 10px;
}

.audio-title {
  font-weight: 700;
  display: flex;
  border-bottom: .05rem solid #ddd;
  padding: 0 3px;
  font-size: 23px;
  align-items: center;
}
</style>